<template>
<form @submit.prevent="" class="messengerToken">
    <div class="form-row">
        <div class="col-12">
            <table class="notification-tbl messenger-modal">
                <thead>
                    <tr>
                        <th> پیام رسان </th>
                        <th class="text-center"> اکشن ها</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in messenger_list" :key="item.id">
                        <td>
                            <img :src="item.icon" :class="'messenger-list-icon '+ item.type" alt="">
                            {{ item.title }}
                        </td>
                        <td class="text-center">
                            <button v-if="item.status == 'publish'" type="button" class="badge badge-pill badge-success" @click="saveChanges(item.link,item.type)">
                                <i class="fa fa-plug"></i>
                                اتصال
                            </button>
                            <button v-if="item.status == 'unpublish'" type="button" class="badge badge-pill badge-secondary">
                                <i class="fa fa-plug"></i> به زودی
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>
</template>

<style lang="scss">
table.messenger-modal td img {
    height: 35px !important;
    width: 35px !important;
    position: relative;
    top: -2px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    padding: 2px;
    margin-left: 5px;
    background: #6c757d;
}
</style>

<script>
// این کامپوننت مودال افزودن و یا اتصال مسنجر جدید در پروفایل کاربر می باشد
export default {
    name: 'MessengerAdd',
    props: ['data'],
    components: {},
    data: function () {
        return {
            messenger_list: [],
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.loadData();
        }, { immediate: true });
    },
    methods: {
        // این متد برای دریافت لیست منسجرها می باشد
        loadData() {
            //ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/user/messenger-list').then(api_response => {
                this.$helpers.api_alert(api_response);
                //در صورتی در خواست ای پی آی پیام موفقیت داشت
                if (api_response.status == 200) {
                    this.messenger_list = api_response.data
                }
            });
        },
        // این متد جهت ارسال درخواست به پرنت جهت کال بک ، افزودن مسنجر جدید به پروفایل و اکانت کاربر می باشد
        saveChanges(link, type) {
            let messenger = {
                link: link,
                type: type
            }
            this.$parent.emitData('AddMessenger', { messenger: messenger }, true);
        },
    },
}
</script>
